import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {CssBaseline, ThemeProvider} from "@mui/material";
import theme from './theme';
import {Provider} from "react-redux";
import store from "./redux/store";
import {
  BrowserRouter as Router
} from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<React.StrictMode>
  <CssBaseline enableColorScheme />
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <App/>
      </Router>
    </Provider>
  </ThemeProvider>
</React.StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
