const CONNECT_CONTROLLER = 0x06
const DISCONNECT_CONTROLLER = 0x07
const CONTROLLER_INPUT = 0x08

const buttonIdxs = [12, 13, 14, 15, 8, 9, 10, 11, 4, 5, 16, 16, 0, 1, 2, 3]
const lt = 6
const rt = 7

const lx = 0
const ly = 1
const rx = 2
const ry = 3

class Gamepad {
  inputChannel;
  gamepad;
  initialized = false;
  buffer;

  constructor(cli, gamepad) {
    this.inputChannel = cli.inputChannel
    this.gamepad = gamepad
    this.buffer = new ArrayBuffer(14);
    const view = new DataView(this.buffer);
    view.setUint8(0, CONTROLLER_INPUT);
    view.setUint8(1, gamepad.index);
    this.tryConnect()
  }


  tryConnect() {
    if (!this.initialized && this.inputChannel && this.inputChannel.readyState === "open") {
      let connectPayload = new Uint8Array(2);
      connectPayload[0] = CONNECT_CONTROLLER;
      connectPayload[1] = this.gamepad.index;
      this.inputChannel.send(connectPayload);
      this.initialized = true;
    }
  }

  disconnect() {
    if (this.initialized && this.inputChannel && this.inputChannel.readyState === "open") {
      let connectPayload = new Uint8Array(2);
      connectPayload[0] = DISCONNECT_CONTROLLER;
      connectPayload[1] = this.gamepad.index;
      this.inputChannel.send(connectPayload);
    }

  }

  sendInput(gamepad) {
    this.tryConnect();
    if (this.inputChannel && this.inputChannel.readyState === "open") {
      let view = new DataView(this.buffer, 2)


      // generate buttons short
      view.setUint16(0, generateButtonsShort(gamepad),true)

      // triggers
      view.setUint8(2, Math.floor(gamepad.buttons[lt].value * 255))
      view.setUint8(3, Math.floor(gamepad.buttons[rt].value * 255))

      // generate axes shorts
      view.setInt16(4, Math.floor(gamepad.axes[lx] * 127))
      view.setInt16(6, Math.floor(gamepad.axes[ly] * -127))

      view.setInt16(8, Math.floor(gamepad.axes[rx] * 127))
      view.setInt16(10, Math.floor(gamepad.axes[ry] * -127))

      this.inputChannel.send(this.buffer);
    }
  }

}

function generateButtonsShort(gamepad) {
  let num = 0;
  for (const [i, btn] of buttonIdxs.entries()) {
    if (gamepad.buttons[btn].pressed) {
      num += (2 ** i)
      console.log(i)
    }
  }
  if (num!==0){
    console.log("buttons: ",num)
  }
  return num
}

export default Gamepad