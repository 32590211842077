import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import signup from "../api/signup";

const signupThunk = createAsyncThunk(
  "auth/signup",
  async (formData) => {
    return await signup(formData);
  }
)

const SignupSlice = createSlice({
  name: "signup",
  initialState: {
    loadingState: "idle",
    errors: {},
  },
  reducers: {
    clearState: (state) => {
      state.loadingState = 'idle'
      state.errors = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(signupThunk.pending, (state, action) => {
      state.loadingState = "loading"
    })

    builder.addCase(signupThunk.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.loadingState = "success"
        state.errors = {}
      } else {
        state.loadingState = "failed"
        state.errors = action.payload.data.errors;
      }
    })

    builder.addCase(signupThunk.rejected, (state, action) => {
      state.loadingState = "failed"
      state.errors = {"__all__": "Unable to reach server"}
    })


  }
})


export {signupThunk}
export const {clearState} = SignupSlice.actions;
export default SignupSlice.reducer