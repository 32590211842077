import {
  Backdrop,
  Box,
  Button, Checkbox, Collapse,
  Dialog,
  DialogActions, DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem, Popover,
  Slider,
  styled, TextField,
  Typography
} from "@mui/material";
import {
  Cancel,
  ChevronLeft, CodeTwoTone, Fullscreen, FullscreenExit,
  ImageAspectRatioTwoTone, NetworkCheck, RefreshTwoTone, Save,
  Settings,
  SpeedTwoTone, VolumeDown,
  VolumeOff,
  VolumeUp
} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setStreamConfig} from "../../redux/SettingsSlice";
import {setVolume} from "../../redux/AudioSlice";
import KeyboardInput from "./inputs/KeyboardInput";
import DialogContext from "@mui/material/Dialog/DialogContext";
import GamepadInput from "./inputs/GamepadInput";

const SettingsDialog = styled(Dialog)((theme) => ({}))

const VolumeControl = () => {
  const [open, setOpen] = useState(false);
  const volume = useSelector(state => state.audio.volume);
  const dispatch = useDispatch();
  const anchorEl = useRef();
  return <>
    <IconButton ref={anchorEl} onClick={() => setOpen(true)}
                sx={{m: 2}}>
      {volume === 0 ? <VolumeOff/> : <VolumeUp/>}
    </IconButton>
    <Popover slotProps={{
      paper: {

        sx: {
          px: 2, display: 'flex',
          flexDirection: "column",
          alignItems: 'center',
          justifyContent: 'center'
        },
      }
    }} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} open={open} onClose={() => setOpen(false)}
             anchorEl={anchorEl.current}>
      <VolumeUp sx={{my: 2}}/>
      <Slider sx={{height: "150px"}} orientation={"vertical"} min={0} max={100} step={10}
              onChange={(e, v) => dispatch(setVolume(v))}
              value={volume}/>
      <VolumeDown sx={{my: 2}}/>
    </Popover>
  </>
}
const Overlay = ({children, cli}) => {
  const [open, setOpen] = useState(false);
  const [inControl, setInControl] = useState(false);
  const [menuOpen, setMenuOpen] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const streamSettings = useSelector(state => state.settings);
  const [settingsState, setSettingState] = useState({});
  const [fullscreen, setFullscreen] = useState(false);
  const moveMult = useSelector(state => state.client_settings.movement_multiplier);
  const scrollMult = useSelector(state => state.client_settings.scroll_multiplier);
  const toggleFullscreen = () => {
    setFullscreen(!fullscreen)
  }

  const handleFullscreenchange = () => {
    setFullscreen(!!document.fullscreenElement)
  }
  useEffect(() => {
    if (fullscreen && !document.fullscreenElement) {
      document.querySelector('body').requestFullscreen();
    } else if ((!fullscreen) && document.fullscreenElement) {
      document.exitFullscreen();
    }
  }, [fullscreen]);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenchange)
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenchange)
    }
  }, [])


  useEffect(() => {
    setSettingState(streamSettings);
  }, [streamSettings]);
  const toggleOpen = () => setOpen(!open);
  const openMenu = (menuId) => {
    return (e) => {
      setAnchorEl(e.currentTarget);
      setMenuOpen(menuId)
    };
  }
  const setValue = (name, value) => {
    setSettingState({...settingsState, [name]: value})
    closeMenu()
  }
  const closeMenu = () => {
    setMenuOpen("");
  }
  return <>
    {children}
    <KeyboardInput cli={cli} inControl={inControl} onControlChange={setInControl} move_multiplier={moveMult}
                   scroll_multiplier={scrollMult}/>
    <GamepadInput cli={cli}/>
    {!inControl && <>

      <Box hidden={inControl} sx={{bgcolor: "rgba(0,0,0,0.2)"}} position={"absolute"} top={0} left={0} height={"100%"}
           width={"100%"}
           display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
        <Typography variant={"h3"} color={"textSecondary"}><Typography component={'span'} variant="h3"
                                                                       color={"textPrimary"}>Double
          click</Typography> to
          take control</Typography>

        <Typography variant={"h3"} color={"textSecondary"}><Typography component={'span'} variant="h3"
                                                                       color={"textPrimary"}>Ctrl +
          M</Typography> to release control</Typography>
      </Box>
      <Box hidden={inControl} display={"flex"} flexDirection={"column"} position={"absolute"} height={"100%"}
           width={"100%"} justifyContent={"space-between"} onDoubleClick={(e) => {
        console.log(e)
        if (!open) {
          setInControl(true)
        }
      }}>
        <Box color={"#fff"} display={"flex"} justifyContent={"flex-start"} flexDirection={"row"}>
          <Button sx={{m: 2}} variant={"text"} color={"inherit"} component={Link} startIcon={<ChevronLeft/>}
                  to={"/"}>Devices</Button>
          <Box flex={1}></Box>
          <VolumeControl/>
          <IconButton onClick={toggleOpen} sx={{m: 2}}><Settings/></IconButton>
        </Box>

        <Box display={"flex"} flexDirection={"row"} justifyContent={"flex-end"}>
          <IconButton sx={{m: 2}} onClick={toggleFullscreen}>{fullscreen ? <FullscreenExit/> :
            <Fullscreen/>}</IconButton>
        </Box>
      </Box>
    </>}

    <SettingsDialog
      fullWidth maxWidth={"xs"} open={open} onClose={toggleOpen}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          dispatch(setStreamConfig({...settingsState}));
          toggleOpen()
        },
      }}
    >
      <DialogTitle>
        Stream Settings
      </DialogTitle>
      <List sx={{width: '100%', bgcolor: 'background.paper'}}>
        <ListSubheader color={"primary"}>Video settings</ListSubheader>
        <ListItemButton dense onClick={openMenu("resolution")}>
          <ListItemIcon>
            <ImageAspectRatioTwoTone/>
          </ListItemIcon>
          <ListItemText primary={"Resolution"}/>
          <ListItemSecondaryAction>
            <Typography variant={"body2"}
                        color={"textSecondary"}> {settingsState.resolution?.width} x {settingsState.resolution?.height}</Typography>
          </ListItemSecondaryAction>
        </ListItemButton>
        <ListItemButton dense onClick={openMenu("framerate")}>
          <ListItemIcon>
            <SpeedTwoTone/>
          </ListItemIcon>
          <ListItemText primary={"Framerate"}/>
          <ListItemSecondaryAction>
            <Typography variant={"body2"}
                        color={"textSecondary"}> {settingsState.framerate} fps</Typography>
          </ListItemSecondaryAction>
        </ListItemButton>
        <ListItemButton onClick={() => setMenuOpen("bitrate")} dense>
          <ListItemIcon>
            <NetworkCheck/>
          </ListItemIcon>
          <ListItemText primary={"Bitrate"}/>
          <ListItemSecondaryAction>
            <Typography variant={"body2"}
                        color={"textSecondary"}> {settingsState.bitrate / (1024 * 1024)} mbps</Typography>
          </ListItemSecondaryAction>
        </ListItemButton>
        <Collapse in={menuOpen === "bitrate"}>
          <ListItem dense>
            <ListItemText inset>
              <Slider onChange={(e, v) => setSettingState({...settingsState, bitrate: v * 1024 * 1024})}
                      valueLabelDisplay="auto" min={0.5} step={0.5}
                      max={25} value={settingsState.bitrate / (1024 * 1024)}/>
            </ListItemText>
          </ListItem>
        </Collapse>
        <ListItem dense>
          <ListItemIcon>
            <ImageAspectRatioTwoTone/>
          </ListItemIcon>
          <ListItemText primary={"Change Resolution"}/>
          <Checkbox size={"small"} onClick={() => setValue('change_resolution', !settingsState.change_resolution)}
                    checked={settingsState.change_resolution}/>
        </ListItem>
        <ListSubheader color={"primary"}>Miscellaneous</ListSubheader>
        <ListItemButton dense onClick={openMenu("idr_interval")}>
          <ListItemIcon>
            <RefreshTwoTone/>
          </ListItemIcon>
          <ListItemText primary={"IDR Interval"}>
          </ListItemText>
          <ListItemSecondaryAction>
            <Typography variant={"body2"}
                        color={"textSecondary"}> {settingsState.idr_interval || 0} frames</Typography>

          </ListItemSecondaryAction>
        </ListItemButton>
        <ListItem dense>
          <ListItemIcon>
            <CodeTwoTone/>
          </ListItemIcon>
          <ListItemText primary={"Source options"}/>
          <TextField size={"small"} defaultValue={settingsState?.extra_opt?.source}
                     placeholder={"extra options for video source"}
                     name={"source"}
                     onChange={(event) => setValue("extra_opt", Object.assign({}, settingsState.extra_opt, {"source": event.target.value}))}/>
        </ListItem>
        <ListItem dense>
          <ListItemIcon>
            <CodeTwoTone/>
          </ListItemIcon>
          <ListItemText primary={"Filter options"}/>
          <TextField size={"small"} defaultValue={settingsState?.extra_opt?.filter}
                     placeholder={"extra options for color filter"}
                     name={"Filter"}
                     onChange={(event) => setValue("extra_opt", Object.assign({}, settingsState.extra_opt, {"filter": event.target.value}))}/>
        </ListItem>
        <ListItem dense>
          <ListItemIcon>
            <CodeTwoTone/>
          </ListItemIcon>
          <ListItemText primary={"Processor options"}/>
          <TextField size={"small"} defaultValue={settingsState?.extra_opt?.processor}
                     placeholder={"extra options for video encoder"}
                     name={"Processor"}
                     onChange={(event) => setValue("extra_opt", Object.assign({}, settingsState.extra_opt, {"processor": event.target.value}))}/>
        </ListItem>
      </List>
      <Menu open={menuOpen === "resolution"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => setValue("resolution", {width: 1280, height: 720})} value="1280x720">
          1280 x 720
        </MenuItem>
        <MenuItem onClick={() => setValue("resolution", {width: 1920, height: 1080})} value="1920x1080">
          1920 x 1080
        </MenuItem>
        <MenuItem onClick={() => setValue("resolution", {width: 2560, height: 1440})} value="2560x1440">
          2560 x 1440
        </MenuItem>
        <MenuItem onClick={() => setValue("resolution", {width: 3840, height: 2160})} value="3840x2160">
          3840 x 2160
        </MenuItem>
        <MenuItem onClick={openMenu("resolution_custom")}>Custom...</MenuItem>
      </Menu>
      <Menu open={menuOpen === "framerate"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => setValue("framerate", 30)}>
          30 fps
        </MenuItem>
        <MenuItem onClick={() => setValue("framerate", 60)}>
          60 fps
        </MenuItem>
        <MenuItem onClick={() => setValue("framerate", 120)}>
          120 fps
        </MenuItem>

      </Menu>
      <Menu open={menuOpen === "idr_interval"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => setValue("idr_interval", 0)}>
          0 frames
        </MenuItem>
        <MenuItem onClick={() => setValue("idr_interval", 500)}>
          500 frames
        </MenuItem>
        <MenuItem onClick={() => setValue("idr_interval", 1000)}>
          1000 frames
        </MenuItem>

      </Menu>
      <DialogActions>
        <Button color={"error"} startIcon={<Cancel/>} type={"submit"}>cancel</Button>
        <Button color={"primary"} startIcon={<Save/>} variant={"outlined"} type={"submit"}>Save</Button>
      </DialogActions>
    </SettingsDialog>
    <Dialog open={menuOpen === "resolution_custom"}
            onClose={closeMenu}
            PaperProps={{
              component: 'form',
              onSubmit: (e) => {
                e.preventDefault();
                let formdata = new FormData(e.currentTarget);
                let dataJson = Object.fromEntries(formdata.entries());
                setValue("resolution", {width: Number(dataJson.width), height: Number(dataJson.height)})
              }
            }}

    >
      <DialogTitle>Custom Resolution</DialogTitle>
      <DialogContent>
        <TextField autoFocus sx={{m: 1}} defaultValue={settingsState?.resolution?.width} label={"Width"}
                   name={"width"}/>
        <TextField sx={{m: 1}} label={"Height"} name={"height"} defaultValue={settingsState?.resolution?.height}/>
      </DialogContent>
      <DialogActions>
        <Button type={"submit"}>Save</Button>
      </DialogActions>
    </Dialog>
  </>
}


export default Overlay;