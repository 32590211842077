import React, {useEffect, useRef, useState} from "react";
import KbdMouse from "./kbd_mouse";
import {Box} from "@mui/material";

const KeyboardInput = ({cli, inControl, onControlChange, move_multiplier, scroll_multiplier}) => {

  const element = useRef();
  const [kbdInp, setKbdInp] = useState(null);
  const releaseInput = () => {
    onControlChange(false);
  }
  useEffect(() => {
    if (element.current && inControl) {
      element.current.focus();
      document.addEventListener("pointerlockchange", lockChangeHandler)
      const promise = element.current.requestPointerLock({
        unadjustedMovement: true,
      });

      if (!promise) {
        element.current.requestPointerLock();
      } else {
        promise.catch((error) => {
          if (error.name === "NotSupportedError") {
            // Some platforms may not support unadjusted movement.
            // You can request again a regular pointer lock.
            console.log("re-locking pointer ")
            return element.current.requestPointerLock();
          }
        }).then(() => {
          lockChangeHandler()
        })
      }
      navigator.keyboard.lock()
      setKbdInp(new KbdMouse(cli))
      return () => {
        navigator.keyboard.unlock();
        document.exitPointerLock();
        if (element.current) {
          element.current.blur();
        }
        document.removeEventListener("pointerlockchange", lockChangeHandler)
      }
    }
  }, [inControl, element]);

  const lockChangeHandler = (event) => {
    console.log(event)
    if (document.pointerLockElement) {
      console.log("collecting input")
    } else {
      releaseInput()
    }
  }

  const mouseMove = (e) => {
    kbdInp?.sendMouseMove({x: e.movementX * move_multiplier, y: e.movementY * move_multiplier})
  }
  const mouseDown = (e) => {
    kbdInp?.sendMouseDown(e.button)
  }
  const mouseUp = (e) => {
    kbdInp?.sendMouseUp(e.button)
  }

  const keyDown = (e) => {
    if (e.key === "m" && e.ctrlKey) {
      releaseInput()
      kbdInp?.sendKeyUp(17)
      kbdInp?.sendKeyUp(e.keyCode)
      return
    }
    e.preventDefault()
    kbdInp?.sendKeyDown(e.keyCode)
  }
  const keyUp = (e) => {
    kbdInp?.sendKeyUp(e.keyCode)
  }

  const mouseScroll = (e) => {
    e.stopPropagation()
    kbdInp?.sendMouseScroll({x: e.deltaX * scroll_multiplier, y: -1 * e.deltaY * scroll_multiplier})
  }
  return <Box sx={{
    height: "min(100%,100px)", width: "min(100%,100px)", position: "absolute", '&:focus': {
      outline: "none",
    }
  }} onMouseMove={mouseMove} onMouseDown={mouseDown} onMouseUp={mouseUp}
              onKeyDown={keyDown} onKeyUp={keyUp} onWheel={mouseScroll} ref={element} tabIndex={0}/>
}
export default KeyboardInput;