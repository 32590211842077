import apiLinks from "./apiLinks";

const auth = async () => {
  const resp = await fetch(apiLinks.auth, {
    method: 'GET',
    credentials: 'include',
    mode: 'cors',
  })
  if (resp.status === 401) {
    return {
      authenticated: false,
      user: undefined
    }
  }
  if (resp.status === 200) {
    const data = await resp.json();
    return {
      user: data,
      authenticated: true
    }
  }
}
export default auth;