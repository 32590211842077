import {
  Avatar, Box, Button,
  Card, CardActionArea,
  CardHeader,
  Chip, CircularProgress,
  Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider,
  Grid,
  IconButton, ListItemIcon, ListItemText, Menu,
  MenuItem, Paper,
  styled, TextField,
  Typography
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {
  addDeviceOtp, clearAddState,
  clearDeleteState,
  clearUpdateState,
  deleteDevice,
  fetchDevices,
  updateDevice
} from "../../redux/DevicesSlice";
import win11Logo from "../../images/win11.svg"
import {Add, Close, Cloud, CloudOff, Delete, Edit, MoreVertRounded, PlayArrow, Send} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {setStreamConfig} from "../../redux/SettingsSlice";


const DeviceCard = styled(Card)((theme) => ({
  width: '300px', alignSelf: 'center', justifySelf: 'center'
}))

const disabled = {}
const Devices = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(state => state.auth.authenticated)
  const devices = useSelector(state => {
    return state.devices.devices
  });

  useEffect(() => {
    dispatch(fetchDevices())
    let handle = setInterval(() => {
      dispatch(fetchDevices())
    }, 10000)
    return () => {
      clearInterval(handle)
    }

  }, [dispatch]);

  const [deviceOptions, setDeviceOpts] = useState(null);
  const [activeDevice, setActiveDevice] = useState(null);

  const [dialog, setDialog] = useState(null);

  const closeOpts = () => {
    setDeviceOpts(null)
  }
  const optOpen = Boolean(deviceOptions);
  const handleDeviceOptOpen = (e, device) => {
    setDeviceOpts(e.currentTarget)
    setActiveDevice(device)
  }

  return <Container sx={{mt: 12}} maxWidth={"lg"}>
    <Typography sx={{my: 4}} color={"textPrimary"} variant={"h4"}>Devices</Typography>
    {!loggedIn &&
      <Typography color={"textSecondary"} variant={"h5"}> Please Login to see your devices</Typography>}
    {
      loggedIn &&

      <Grid container spacing={2} justifyContent={"space-around"}>
        <Grid sx={{justifyContent: 'center', display: 'flex'}} item md={4}>
          <DeviceCard variant={"elevation"} type={"glass"} sx={{alignSelf: 'stretch'}}>
            <CardActionArea sx={{height: "100%", verticalAlign: "middle"}} onClick={()=>setDialog("add")}>
              <Typography color={"textSecondary"} variant={"h5"} textAlign={"center"}>+ Add Device</Typography>
            </CardActionArea>
          </DeviceCard>

        </Grid>
        {devices.map((d) => {
          return <Grid key={d.id} sx={{justifyContent: 'center', display: 'flex'}} item md={4}>
            <DeviceCard variant={"elevation"} type={"glass"}>
              <CardHeader
                avatar={<Avatar src={win11Logo} alt={"Windows 11"}/>}
                action={<><IconButton component={Link} color={"success"} to={"/stream/" + d.id}
                                      disabled={!d.is_connected}><PlayArrow/></IconButton><IconButton
                  onClick={e => handleDeviceOptOpen(e, d)}><MoreVertRounded/>
                </IconButton></>}
                title={d.name}
                subheader={d.is_connected ? <Chip icon={<Cloud/>} size={"small"} label={"Connected"}/> :
                  <Chip icon={<CloudOff/>} size={"small"} label={"Disconnected"}/>}
              />
            </DeviceCard>
          </Grid>
        })}
      </Grid>
    }
    <Menu open={optOpen} onClose={closeOpts} anchorEl={deviceOptions}>
      <MenuItem sx={{width: "150px"}} onClick={() => {
        setDialog("edit");
        closeOpts()
      }}>
        <ListItemIcon>
          <Edit/>
        </ListItemIcon>
        <ListItemText>
          Edit
        </ListItemText>
      </MenuItem>
      <MenuItem onClick={() => {
        setDialog("delete");
        closeOpts()
      }}>
        <ListItemIcon>
          <Delete/>
        </ListItemIcon>
        <ListItemText>
          Delete
        </ListItemText>
      </MenuItem>
    </Menu>
    <DeleteDialog open={dialog === "delete"} onClose={() => setDialog(null)} device={activeDevice}/>
    <EditDialog open={dialog === "edit"} onClose={() => setDialog(null)} device={activeDevice}/>
    <AddDeviceDialog open={dialog === "add"} onClose={()=>setDialog(null)}/>
  </Container>

}

const DeleteDialog = ({open, onClose, device}) => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state.devices.deleteState);

  useEffect(() => {
    if (state === "success") {
      dispatch(clearDeleteState())
      onClose();
    }
  }, [state]);
  const handleDelete = () => {
    dispatch(deleteDevice(device.id))
  }
  return <Dialog open={open} onClose={onClose} fullWidth maxWidth={"xs"}>
    <DialogTitle>
      Delete "{device?.name}"
    </DialogTitle>
    <DialogContent>
      Are you sure?
    </DialogContent>
    <Divider/>
    <DialogActions>
      <Button startIcon={<Close/>} variant={"text"} onClick={onClose} color="inherit"> Cancel </Button>
      <Button startIcon={<Delete/>} sx={{width: "100px"}} disabled={state === "loading"} variant={"contained"}
              onClick={handleDelete}
              color="error"> {state === "loading" ?
        <CircularProgress size={24}/> : "Delete"} </Button>
    </DialogActions>
  </Dialog>
}
const EditDialog = ({open, onClose, device}) => {
  const dispatch = useDispatch();
  const [draftDevice, setDraftDevice] = useState(null);
  useEffect(() => {
    setDraftDevice(device);
  }, [device]);
  const state = useSelector((state) => state.devices.updateState);

  useEffect(() => {
    if (state === "success") {
      dispatch(clearUpdateState())
      onClose();
    }
  }, [state]);
  const handleUpdate = () => {
    dispatch(updateDevice(draftDevice))
  }
  return <Dialog component={"form"} open={open} onClose={onClose} fullWidth maxWidth={"xs"}
                 onSubmit={(e) => {
                   e.preventDefault();
                   handleUpdate();
                 }}
  >
    <DialogTitle>
      Edit device
    </DialogTitle>
    <DialogContent sx={{py: 2}}>
      <TextField autoFocus sx={{my: 2}} name={"name"} label={"Device name"} fullWidth={true} type={"text"}
                 value={draftDevice?.name || ""}
                 onChange={e => setDraftDevice(d => Object.assign({}, d, {"name": e.target.value}))}/>
    </DialogContent>
    <DialogActions>
      <Button startIcon={<Close/>} variant={"text"} onClick={onClose} color="inherit"> Cancel </Button>
      <Button startIcon={<Send/>} sx={{width: "100px"}} disabled={state === "loading"} variant={"contained"}
              type={"submit"}
              color="primary"> {state === "loading" ?
        <CircularProgress size={24}/> : "Update"} </Button>
    </DialogActions>
  </Dialog>
}

const AddDeviceDialog = ({open, onClose}) => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");

  const state = useSelector((state) => state.devices.addState);

  useEffect(() => {
    if (state === "success") {
      dispatch(clearAddState())
      onClose();
    }
  }, [state]);
  const handleAdd = () => {
    dispatch(addDeviceOtp(otp))
  }
  return <Dialog component={"form"} open={open} onClose={onClose} fullWidth maxWidth={"xs"}
                 onSubmit={(e) => {
                   e.preventDefault();
                   handleAdd();
                 }}
  >
    <DialogTitle>
      Add Device
    </DialogTitle>
    <DialogContent sx={{py: 2}}>
      <TextField autoFocus sx={{my: 2}} name={"otp"} label={"One-Time Pin"} fullWidth={true} type={"text"} value={otp}
                 error={state==="failed"} helperText={state==="failed"?"Invalid otp try again":""}
                 onChange={e => setOtp(e.target.value)}/>
    </DialogContent>
    <DialogActions>
      <Button startIcon={<Close/>} variant={"text"} onClick={onClose} color="inherit"> Cancel </Button>
      <Button startIcon={<Add/>} sx={{width: "100px"}} disabled={state === "loading"} variant={"contained"}
              type={"submit"}
              color="primary"> {state === "loading" ?
        <CircularProgress size={24}/> : "Add"} </Button>
    </DialogActions>
  </Dialog>
}
export default Devices