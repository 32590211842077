import {setStreamConfig} from "../../redux/SettingsSlice";
import {
  Button,
  Checkbox, Collapse,
  Dialog,
  DialogActions,
  DialogContent, DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Slider,
  styled, Tab, Tabs,
  TextField,
  Typography
} from "@mui/material";
import {
  Cancel, CodeTwoTone, ColorLens, ColorLensTwoTone, Hd, HdTwoTone,
  ImageAspectRatioTwoTone, MemoryTwoTone, MouseTwoTone,
  NetworkCheck, OndemandVideo, OndemandVideoTwoTone,
  RefreshTwoTone,
  Save,
  Settings,
  SpeedTwoTone, TimelineTwoTone, TvTwoTone, UnfoldMore, VideoSettings, VideoSettingsTwoTone
} from "@mui/icons-material";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setClientConfig} from "../../redux/ClientSettingsSlice";

const SettingsDialog = styled(Dialog)((theme) => ({
  '& .MuiDialog-paper': {
    height: "min(100%,500px)"
  }
}))

const StyledContent = styled(DialogContent)(theme => ({
  '&.MuiDialogContent-root': {
    padding: 0,
  }
}))

const OverlaySetting = () => {
  const dispatch = useDispatch();
  const streamSettings = useSelector(state => state.settings);
  const storedClientSettings = useSelector(state => state.client_settings);
  const [settingsState, setSettingState] = useState({});
  const [clientSetting, setClientSetting] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const toggleOpen = () => setOpen(!open);

  const openMenu = (menuId) => {
    return (e) => {
      setAnchorEl(e.currentTarget);
      setMenuOpen(menuId)
    };
  }
  useEffect(() => {
    setSettingState(streamSettings);
  }, [streamSettings]);

  useEffect(() => {
    setClientSetting(storedClientSettings);
  }, [storedClientSettings]);
  const setValue = (name, value) => {
    setSettingState({...settingsState, [name]: value})
    closeMenu()
  }
  const setClientValue = (name, value) => {
    setClientSetting({...clientSetting, [name]: value})
    closeMenu()
  }
  const closeMenu = () => {
    setMenuOpen("");
  }

  const handlehardware_accelerationChange = () => {
    const changedValue = settingsState.hardware_acceleration === "prefer-software" ? "prefer-hardware" : "prefer-software";
    setValue('hardware_acceleration', changedValue);
  }
  return <>
    <IconButton onClick={toggleOpen} sx={{m: 2}}><Settings/></IconButton>
    <SettingsDialog
      fullWidth maxWidth={"xs"} open={open} onClose={toggleOpen}
      PaperProps={{
        component: 'form',
        onSubmit: (event) => {
          event.preventDefault();
          dispatch(setStreamConfig({...settingsState}));
          dispatch(setClientConfig({...clientSetting}));
          toggleOpen()
        },
      }}
    >
      <DialogTitle>
        Settings
      </DialogTitle>
      <Tabs value={activeTab} onChange={(e, v) => setActiveTab(v)}>
        <Tab label={"Stream"}></Tab>
        <Tab label={"Client"}></Tab>
        <Tab label={"Input"}></Tab>
        <Tab label={"About"}></Tab>
      </Tabs>
      <StyledContent scroll={"paper"}>
        {activeTab === 0 && <>
          <List sx={{width: '100%', bgcolor: 'background.paper'}}>
            <ListSubheader color={"primary"}>Video quality</ListSubheader>
            <ListItemButton dense onClick={openMenu("resolution")}>
              <ListItemIcon>
                <HdTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Resolution"}/>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {settingsState.resolution?.width} x {settingsState.resolution?.height}</Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton dense onClick={openMenu("framerate")}>
              <ListItemIcon>
                <SpeedTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Framerate"}/>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {settingsState.framerate} fps</Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton dense onClick={openMenu("codec")}>
              <ListItemIcon>
                <OndemandVideoTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Video Codec"}/>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {settingsState.codec}</Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton dense onClick={openMenu("color_format")}>
              <ListItemIcon>
                <ColorLensTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Color"}/>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {settingsState.color_format}</Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton onClick={()=>setMenuOpen("bitrate")} dense>
              <ListItemIcon>
                <NetworkCheck/>
              </ListItemIcon>
              <ListItemText primary={"Bitrate"}/>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {settingsState.bitrate / (1024 * 1024)} mbps</Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <Collapse in={menuOpen==="bitrate"}>
              <ListItem dense>
                <ListItemText inset>
                  <Slider onChange={(e, v) => setSettingState({...settingsState, bitrate: v * 1024 * 1024})}
                          valueLabelDisplay="auto" min={0.5} step={0.5} sx={{width: "95%"}}
                          max={25} value={settingsState.bitrate / (1024 * 1024)}/>
                </ListItemText>
              </ListItem>
            </Collapse>
            <ListSubheader color={"primary"}>Devices</ListSubheader>
            <ListItem dense>
              <ListItemIcon>
                <MemoryTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Display Adapter"}/>
              <TextField size={"small"} defaultValue={settingsState?.adapter_idx} type={'number'}
                         name={"adapter_idx"} onChange={(event) => setValue("adapter_idx", event.target.value)}/>
            </ListItem>
            <ListItem dense>
              <ListItemIcon>
                <TvTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Monitor"}/>
              <TextField size={"small"} variant={"outlined"} defaultValue={settingsState?.output_idx} type={'number'}
                         name={"output_idx"} onChange={(event) => setValue("output_idx", event.target.value)}/>
            </ListItem>
            <ListItem dense>
              <ListItemIcon>
                <ImageAspectRatioTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Change Resolution"}/>
              <Checkbox size={"small"} onClick={() => setValue('change_resolution', !settingsState.change_resolution)}
                        checked={settingsState.change_resolution}/>
            </ListItem>

            <ListSubheader color={"primary"}>Advanced Options</ListSubheader>
            <ListItemButton dense onClick={openMenu("idr_interval")}>
              <ListItemIcon>
                <RefreshTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"IDR Interval"}>
              </ListItemText>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {settingsState.idr_interval || 0} frames</Typography>

              </ListItemSecondaryAction>
            </ListItemButton>
            <ListItem dense>
              <ListItemIcon>
                <CodeTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Source options"}/>
              <TextField size={"small"} defaultValue={settingsState?.extra_opt?.source}
                         placeholder={"extra options for video source"}
                         name={"source"}
                         onChange={(event) => setValue("extra_opt", Object.assign({}, settingsState.extra_opt, {"source": event.target.value}))}/>
            </ListItem>
            <ListItem dense>
              <ListItemIcon>
                <CodeTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Filter options"}/>
              <TextField size={"small"} defaultValue={settingsState?.extra_opt?.filter}
                         placeholder={"extra options for color filter"}
                         name={"Filter"}
                         onChange={(event) => setValue("extra_opt", Object.assign({}, settingsState.extra_opt, {"filter": event.target.value}))}/>
            </ListItem>
            <ListItem dense>
              <ListItemIcon>
                <CodeTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Processor options"}/>
              <TextField size={"small"} defaultValue={settingsState?.extra_opt?.processor}
                         placeholder={"extra options for video encoder"}
                         name={"Processor"}
                         onChange={(event) => setValue("extra_opt", Object.assign({}, settingsState.extra_opt, {"processor": event.target.value}))}/>
            </ListItem>
          </List>
        </>}
        {activeTab === 1 && <>
          <List sx={{width: '100%', bgcolor: 'background.paper', minHeight: '100%'}}>
            <ListSubheader color={"primary"}>Decoder settings</ListSubheader>
            <ListItem dense>
              <ListItemIcon>
                <VideoSettingsTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Codec String"}/>
              <TextField size={"small"} defaultValue={settingsState?.codec_string}
                         name={"Codec String"} onChange={(event) => setValue("codec_string", event.target.value)}/>
            </ListItem>
            <ListItem dense>
              <ListItemIcon>
                <SpeedTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Hardware Acceleration"}/>
              <Checkbox size={"small"} onClick={() => handlehardware_accelerationChange()}
                        checked={settingsState.hardware_acceleration === 'prefer-hardware'}/>
            </ListItem>
          </List>
        </>}
        {activeTab === 2 && <>
          <List sx={{width: '100%', bgcolor: 'background.paper', minHeight: '100%'}}>
            <ListSubheader color={"primary"}>Mouse</ListSubheader>
            <ListItemButton dense onClick={openMenu("cursor_capture")}>
              <ListItemIcon>
                <TimelineTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Optimize for"}/>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {clientSetting.cursor_capture ? "Gaming" : "Desktop"}</Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <ListItemButton dense onClick={openMenu("movement_multiplier")}>
              <ListItemIcon>
                <MouseTwoTone/>
              </ListItemIcon>
              <ListItemText primary={"Mouse sensitivity"}/>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {clientSetting.movement_multiplier} x</Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <Collapse in={menuOpen === "movement_multiplier"}>
              <ListItem dense>
                <ListItemText inset>
                  <Slider onChange={(e, v) => setClientSetting({...clientSetting, movement_multiplier: v})}
                          valueLabelDisplay="auto" min={0.01} step={0.01} sx={{width: "95%"}}
                          max={5} value={clientSetting.movement_multiplier}/>
                </ListItemText>
              </ListItem>
            </Collapse>
            <ListItemButton dense onClick={openMenu("scroll_multiplier")}>
              <ListItemIcon>
                <UnfoldMore/>
              </ListItemIcon>
              <ListItemText primary={"Scroll sensitivity"}/>
              <ListItemSecondaryAction>
                <Typography variant={"body2"}
                            color={"textSecondary"}> {clientSetting.scroll_multiplier} x</Typography>
              </ListItemSecondaryAction>
            </ListItemButton>
            <Collapse in={menuOpen === "scroll_multiplier"}>
              <ListItem dense>
                <ListItemText inset>
                  <Slider onChange={(e, v) => setClientSetting({...clientSetting, scroll_multiplier: v})}
                          valueLabelDisplay="auto" min={0.01} step={0.01} sx={{width: "95%"}}
                          max={5} value={clientSetting.scroll_multiplier}/>
                </ListItemText>
              </ListItem>
            </Collapse>
          </List>
        </>}
        {activeTab === 3 && <Typography paddingY={5} textAlign={"center"}>Copyright Krishna Chaitanya</Typography>}
      </StyledContent>
      <Menu open={menuOpen === "resolution"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => setValue("resolution", {width: 1280, height: 720})} value="1280x720">
          1280 x 720
        </MenuItem>
        <MenuItem onClick={() => setValue("resolution", {width: 1920, height: 1080})} value="1920x1080">
          1920 x 1080
        </MenuItem>
        <MenuItem onClick={() => setValue("resolution", {width: 2560, height: 1440})} value="2560x1440">
          2560 x 1440
        </MenuItem>
        <MenuItem onClick={() => setValue("resolution", {width: 3840, height: 2160})} value="3840x2160">
          3840 x 2160
        </MenuItem>
        <MenuItem onClick={openMenu("resolution_custom")}>Custom...</MenuItem>
      </Menu>
      <Menu open={menuOpen === "framerate"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => setValue("framerate", 30)}>
          30 fps
        </MenuItem>
        <MenuItem onClick={() => setValue("framerate", 60)}>
          60 fps
        </MenuItem>
        <MenuItem onClick={() => setValue("framerate", 120)}>
          120 fps
        </MenuItem>

      </Menu>

      <Menu open={menuOpen === "codec"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => setValue("codec", "h264")}>H264</MenuItem>
        <MenuItem onClick={() => setValue("codec", "hevc")}>HEVC</MenuItem>
      </Menu>
      <Menu open={menuOpen === "color_format"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => setValue("color_format", "rgb")}>RGB</MenuItem>
        <MenuItem onClick={() => setValue("color_format", "ayuv")}>AYUV</MenuItem>
        <MenuItem onClick={() => setValue("color_format", "nv12")}>NV12</MenuItem>
      </Menu>
      <Menu open={menuOpen === "idr_interval"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => setValue("idr_interval", 0)}>
          0 frames
        </MenuItem>
        <MenuItem onClick={() => setValue("idr_interval", 500)}>
          500 frames
        </MenuItem>
        <MenuItem onClick={() => setValue("idr_interval", 1000)}>
          1000 frames
        </MenuItem>
      </Menu>
      <Menu open={menuOpen === "cursor_capture"} anchorEl={anchorEl} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} onClose={closeMenu}>
        <MenuItem onClick={() => setClientValue("cursor_capture", true)}>
          Gaming
        </MenuItem>
        <MenuItem onClick={() => setClientValue("cursor_capture", false)}>
          Desktop
        </MenuItem>
      </Menu>
      <DialogActions>
        <Button color={"error"} startIcon={<Cancel/>} type={"submit"}>cancel</Button>
        <Button color={"primary"} startIcon={<Save/>} variant={"outlined"} type={"submit"}>Save</Button>
      </DialogActions>
    </SettingsDialog>
    <Dialog open={menuOpen === "resolution_custom"}
            onClose={closeMenu}
            PaperProps={{
              component: 'form',
              onSubmit: (e) => {
                e.preventDefault();
                let formdata = new FormData(e.currentTarget);
                let dataJson = Object.fromEntries(formdata.entries());
                setValue("resolution", {width: Number(dataJson.width), height: Number(dataJson.height)})
              }
            }}

    >
      <DialogTitle>Custom Resolution</DialogTitle>
      <StyledContent>
        <TextField autoFocus sx={{m: 1}} defaultValue={settingsState?.resolution?.width} label={"Width"}
                   name={"width"}/>
        <TextField sx={{m: 1}} label={"Height"} name={"height"}
                   defaultValue={settingsState?.resolution?.height}/>
      </StyledContent>
      <DialogActions>
        <Button type={"submit"}>Save</Button>
      </DialogActions>
    </Dialog>
  </>
}
export default OverlaySetting;