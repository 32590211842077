import {
  AppBar, Avatar, Box, Button, IconButton, Menu, MenuItem, Toolbar, Typography, useTheme
} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {checkAuth, logoutThunk} from "../../redux/AuthSlice";
import {Routes, Route, Link, useNavigate} from "react-router-dom";
import logo from "../../images/icon.svg"
import Devices from "../Devices/Devices";
import OverlaySetting from "../../components/StreamClient/OverlaySetting";

const WelcomeNode = ({user, ...props}) => {

  return (<Typography color={"textPrimary"} alignSelf={'center'} textAlign={'center'} variant={"h2"}>
    Welcome, <Typography component={'span'} variant={"h2"} color={"primary"}>{user?.last_name}</Typography>
  </Typography>)

}

const UserBadge = ({user, logout}) => {
  const theme = useTheme();
  const [menuBtn, setMenuBtn] = useState(null);
  const open = Boolean(menuBtn)
  const handleClick = (e) => {
    setMenuBtn(e.currentTarget)
  }
  const handleClose = () => {
    setMenuBtn(null)
  }
  return (<>
    <IconButton ref={menuBtn} onClick={handleClick}><Avatar
      sx={{background: theme.palette.secondary.main}}>{user.first_name[0]}</Avatar></IconButton>
    <Menu onClose={handleClose} anchorEl={menuBtn} open={open}>
      <MenuItem onClick={logout}>Logout</MenuItem>
    </Menu>
  </>)
}
const Home = () => {
  const user = useSelector(state => state.auth.user);
  const navigate = useNavigate()
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(logoutThunk())
  }
  useEffect(() => {
    dispatch(checkAuth())
  }, []);

  return (<>
    <AppBar variant={"elevation"} elevation={0} color={"transparent"}>
      <Toolbar variant={"regular"}>
        <img style={{height: "auto", width: 32}} src={logo} alt={"logo"}/>
        <Box flex={1}/>

        <OverlaySetting/>
        {user ?
          <UserBadge user={user} logout={logout}/>
          : <Button component={Link} to={"/login"}>Login</Button>}
      </Toolbar>
    </AppBar>
    <Routes>
      <Route path={"/"} element={<Devices/>}></Route>
    </Routes>

  </>)
}
export default Home