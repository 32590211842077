class webcodec {
  lastFrame = null;
  canvas = null;
  ctx = null;
  onResChange;
  onDebugUpdate;

  resolution = {
    width: 0,
    height: 0
  }

  isConfigured = false;

  paint(timestamp) {
    if (this.lastFrame && this.ctx) {
      let res = {
        width: this.lastFrame.displayWidth,
        height: this.lastFrame.displayHeight,
      };
      if (res.width !== this.resolution.width || res.height !== this.resolution.height) {
        console.log("wrong resolution")
        this.onResChange && this.onResChange(res)
        this.resolution = res
      }

      this.ctx.drawImage(this.lastFrame, 0, 0)
    }
    if (this.lastFrame) {
      this.lastFrame.close()
      this.lastFrame = null;
    }
  }

  #onOutput(frame) {
    if (this.lastFrame) {
      this.lastFrame.close()
    }
    this.lastFrame = frame;
    requestAnimationFrame(this.paint.bind(this))
  }

  #onError(e) {
    console.error(e)
  }

  constructor(cli, opt) {

    this.decoder = new window.VideoDecoder({
      output: this.#onOutput.bind(this),
      error: this.#onError.bind(this)
    })
    if (!opt?.webcodecString) {
      opt = {
        webcodecString: "avc1.64003E",
        hardwareAcceleration: "prefer-hardware",
      }
    }
    this.decoder.configure({
      codec: opt.webcodecString,
      // codec: 'avc1.42001E',
      optimizeForLatency: true,
      hardwareAcceleration: opt.hardwareAcceleration,
    })
    cli.onVideoFrameReceived = this.pushFrame.bind(this);

  }

  pushFrame(frame) {
    if (this.decoder) {
      const key = isRefFrame(frame)

      if (key && !this.isConfigured) {
        this.isConfigured = true;
      }
      this.decoder.decode(new window.EncodedVideoChunk({
        data: frame,
        type: key ? "key" : "delta",
        timestamp: Date.now(),
      }));
    }
  }

  setCanvas(canvas) {
    this.canvas = canvas;
    this.ctx = canvas.getContext("2d");
  }

  destroy() {
    this.decoder.close()
    this.decoder = null;
  }
}

export const isRefFrame = (buffer) => {
  return buffer[4] === 0x67 || buffer[4] === 0x68 ||  buffer[4] === 0x40 ||  buffer[4] === 0x3F;
}

export default webcodec