import apiLinks from "../../api/apiLinks";


const JoinRequest = "join_request"
const JoinResponse = "join_response"


const STATUSES = {

  REQUESTED: "reqeusted",
  ACCEPTED: "accepted",
  REJECTED: "rejected",

  CONNECTING: "connecting",
  CONNECTED: "connected",

  CLOSED: "closed"
}

class GAWSClient {
  status;
  ws;
  #turnCred;
  #msgHandlers = {};

  joinTimer;

  onStatusChanged;
  onConnect;
  onDisconnect;

  setMsgHandler(source, handler) {
    this.#msgHandlers[source] = handler
  }

  removeMsgHandler(source) {
    delete this.#msgHandlers[source]
  }

  set_status(status) {
    this.status = status
    if (this.onStatusChanged) {
      this.onStatusChanged(this.status)
    }

    if (status === STATUSES.ACCEPTED) {
      this.onConnect && this.onConnect()
    }

    if (status === STATUSES.CLOSED) {
      this.onDisconnect && this.onDisconnect()
      console.log("disconnected callback")
    }

  }

  constructor() {
    this.set_status(STATUSES.CONNECTING)
    this.ws = new WebSocket(apiLinks.ws)
    this.ws.onopen = this._openHandler.bind(this)
    this.ws.onclose = this._closeHandler.bind(this)
    this.ws.onmessage = this._msgHandler.bind(this)
  }

  send(payload) {
    console.log("outgoing msg", payload)
    this.ws.send(JSON.stringify(payload))
  }

  #forwardMsg(data) {
    let handler = this.#msgHandlers[data.source.id]
    if (handler) {
      handler(data)
    }
    console.debug(data)
  }

  get turnCred() {
    return this.#turnCred
  }

  _msgHandler(e) {
    const data = JSON.parse(e.data)
    console.debug("incoming msg", data)
    switch (data.type) {
      case JoinResponse:
        this.#turnCred = data.payload
        this.set_status(STATUSES.ACCEPTED)
        clearTimeout(this.joinTimer);
        this.joinTimer = null;
        break
      default:
        this.#forwardMsg(data)
    }
  }


  _closeHandler() {
    this.set_status(STATUSES.CLOSED)
    console.log("Websocket Closed")
  }

  _openHandler() {
    this.set_status(STATUSES.CONNECTED)
    this.send({type: JoinRequest, payload: {version: "0.1"}})
    this.set_status(STATUSES.REQUESTED)
    this.joinTimer = setTimeout(() => {
      console.log("failed to join")
      this.destroy();
    }, 5000)
  }


  destroy() {
    this.ws.close()
  }
}

export default GAWSClient