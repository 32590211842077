const KEYDOWN = 0x10;
const KEYUP = 0x11;
const MOUSEDOWN = 0x00;
const MOUSEUP = 0x01;
const MOUSEMOVE = 0x01;
const MOUSESCROLL = 0x05;
const MOUSEMOVEABSOLUTE = 0x09;


class KbdMouse {
  inputChannel;

  constructor(cli) {
    this.inputChannel = cli.inputChannel
  }

  btnBuffer = new Uint8Array(2);
  mouseBuffer = new ArrayBuffer(9);

  sendInput(payload) {
    if (this.inputChannel && this.inputChannel.readyState === "open") {
      this.inputChannel.send(payload)
    }
  }

  sendKeyDown(payload) {
    if (this.inputChannel && this.inputChannel.readyState === "open") {
      this.btnBuffer[0] = KEYDOWN;
      this.btnBuffer[1] = payload;
      this.inputChannel.send(this.btnBuffer)
      console.log("keydown")
    }
  }

  sendKeyUp(payload) {
    if (this.inputChannel && this.inputChannel.readyState === "open") {
      this.btnBuffer[0] = KEYUP;
      this.btnBuffer[1] = payload;
      this.inputChannel.send(this.btnBuffer)
    }
  }

  sendMouseDown(payload) {
    if (this.inputChannel && this.inputChannel.readyState === "open") {
      if (payload === 0) {
        this.btnBuffer[0] = 0x02;
        this.btnBuffer[1] = MOUSEDOWN;
      }
      if (payload === 1) {
        this.btnBuffer[0] = 0x04;
        this.btnBuffer[1] = MOUSEDOWN;
      }
      if (payload === 2) {
        this.btnBuffer[0] = 0x03;
        this.btnBuffer[1] = MOUSEDOWN;
      }
      this.inputChannel.send(this.btnBuffer)
    }
  }

  sendMouseUp(payload) {
    if (this.inputChannel && this.inputChannel.readyState === "open") {
      if (payload === 0) {
        this.btnBuffer[0] = 0x02;
        this.btnBuffer[1] = MOUSEUP;
      }
      if (payload === 1) {
        this.btnBuffer[0] = 0x04;
        this.btnBuffer[1] = MOUSEUP;
      }
      if (payload === 2) {
        this.btnBuffer[0] = 0x03;
        this.btnBuffer[1] = MOUSEUP;
      }
      this.inputChannel.send(this.btnBuffer)
    }
  }

  sendMouseMove(payload) {
    if (this.inputChannel && this.inputChannel.readyState === "open") {
      let dv = new DataView(this.mouseBuffer)
      dv.setInt8(0, MOUSEMOVE);
      dv.setInt16(1, payload.x, true);
      dv.setInt16(5, payload.y, true);
      this.inputChannel.send(this.mouseBuffer)
    }
  }
  sendMouseMoveAbs(payload) {
    if (this.inputChannel && this.inputChannel.readyState === "open") {
      let dv = new DataView(this.mouseBuffer)
      dv.setInt8(0, MOUSEMOVEABSOLUTE);
      dv.setInt16(1, payload.x, true);
      dv.setInt16(5, payload.y, true);
      this.inputChannel.send(this.mouseBuffer)
    }
  }

  sendMouseScroll(payload) {
    if (this.inputChannel && this.inputChannel.readyState === "open") {
      let dv = new DataView(this.mouseBuffer)
      dv.setInt8(0, MOUSESCROLL);
      dv.setInt16(1, payload.x, true);
      dv.setInt16(5, payload.y, true);
      this.inputChannel.send(this.mouseBuffer)
    }
  }
}
export default KbdMouse