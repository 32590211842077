class VideoProcessor {
  onVideoFrame;
  onBadFrame;

  buffer;
  bufferView;

  badframe = true;
  vidIdx;

  constructor() {
    this.buffer = new ArrayBuffer(0, {maxByteLength: 1024 * 1024 * 10});
    this.bufferView = new Uint8Array(this.buffer);
    this.badframe = true;
  }

  #sendFrame() {
    if (this.onVideoFrame) {
      this.onVideoFrame(this.bufferView.slice(0, this.bufferView.length))
    }
  }

  #handleBadFrame() {
    console.log("badFrame", this.vidIdx, this.bufferView[5])
    this.onBadFrame && this.onBadFrame()
  }

  push(packet) {

    let data = new Uint8Array(packet, 0, 2)
    let videoFrame = new Uint8Array(packet, 2)
    if (this.vidIdx === -1) {
      // if uninitialized, set to first frame.
      this.vidIdx = data[0]
    }
    // next video frame number
    let nextNum = (this.vidIdx + 1) % 256

    // if video frame idx == current packet idx data should be appended to current buffer
    if (this.vidIdx === data[0]) {
      const oldLength = this.bufferView.length;
      this.buffer.resize(oldLength + videoFrame.length);
      this.bufferView.set(videoFrame, oldLength)
    } else if (nextNum === data[0]) {
      this.buffer.resize(videoFrame.length);
      this.bufferView.set(videoFrame)
    } else {
      this.buffer.resize(videoFrame.length)
      this.bufferView.set(videoFrame)
      this.badframe = true
    }
    this.vidIdx = data[0]

    // if this is the last packet of the frame
    if (data[1] === 0) {
      if (this.badframe && (this.bufferView[4] === 103 || this.bufferView[4] === 0x40)) {
        this.badframe = false;
      }
      if (!this.badframe) {
        let size = this.bufferView[0] + this.bufferView[1] * 256 + this.bufferView[2] * 256 * 256 + this.bufferView[3] * 256 * 256 * 256;
        if (this.bufferView.length !== size) {
          this.badframe = true
          return
        }
        this.bufferView.set([0, 0, 0, 1], 0)
        this.#sendFrame();
      }
    }
    if (this.badframe)
      this.#handleBadFrame()
  }
}

export default VideoProcessor
