import {styled} from "@mui/material";


const AppContainer = styled('div', {
  name: 'GAWebApp',
  slot: 'root-container',
})(({theme}) => ({
  height: "100%",
  width: "100%",
  display: 'flex',
  position: 'absolute',
}))


const FullBackground = styled('div',
  {
    name: 'GAWebApp',
    slot: 'background'
  })(
  ({theme}) => ({
    // backgroundImage: `url('${backgroundImage}')`,
    backgroundSize: 'cover',
    // background: rgb(44,104,162);
    background: 'radial-gradient( circle at 70vw 30vh, rgba(19,47,74,1) 0%, rgba(0,0,0,1) 60%)',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    display: 'block',
    height: "100%",
    width: "100%",
    zIndex: -1,
  })
)

const BaseLayout = (props) => {
  return (<AppContainer>
    {/*<BlurFilter/>*/}
    <FullBackground/>
    {props.children}
  </AppContainer>)
}

export default BaseLayout