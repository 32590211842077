import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {addDeviceOtpApi, deleteDeviceApi, getDevices, updateDeviceApi} from "../api/devices";

const fetchDevices = createAsyncThunk("devices/list", async () => {
  try {
    const data = await getDevices();
    return {success: true, data}
  } catch (e) {
    return {success: false, errors: e}
  }
})
const deleteDevice = createAsyncThunk("devices/delete", async (deviceId, thunkAPI) => {
  try {
    const data = await deleteDeviceApi(deviceId);
    thunkAPI.dispatch(fetchDevices())
    return {success: true, data}
  } catch (e) {
    return {success: false, errors: e}
  }
})
const updateDevice = createAsyncThunk("devices/update", async (device, thunkAPI) => {
  try {
    const data = await updateDeviceApi(device);
    thunkAPI.dispatch(fetchDevices())
    return {success: true, data}
  } catch (e) {
    return {success: false, errors: e}
  }
})

const addDeviceOtp = createAsyncThunk("devices/add", async (otp, thunkAPI) => {
  try {
    const data = await addDeviceOtpApi(otp);
    thunkAPI.dispatch(fetchDevices())
    return {success: true, data}
  } catch (e) {
    return {success: false, errors: e}
  }
})


const DevicesSlice = createSlice({
  name: "devices", initialState: {
    loadingState: "idle", devices: [], errors: {}, deleteState: "idle", updateState: "idle", addState: "idle"
  }, reducers: {
    clearState: (state) => {
      state.loadingState = 'idle'
    }, clearDeleteState: (state) => {
      state.deleteState = 'idle'
    }, clearUpdateState: (state) => {
      state.updateState = 'idle'
    }, clearAddState: (state)=>{
      state.addState = "idle"
    }
  }, extraReducers: (builder) => {
    builder.addCase(fetchDevices.pending, (state, action) => {
      state.loadingState = "loading"
    })

    builder.addCase(fetchDevices.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.loadingState = "success"
        state.errors = {}
        state.devices = action.payload.data
      } else {
        state.loadingState = "failed"
        state.errors = action.payload.errors;
      }
    })
    builder.addCase(fetchDevices.rejected, (state, action) => {
      state.loadingState = "failed"
      state.errors = action.payload.errors
    })
    builder.addCase(deleteDevice.pending, (state, action) => {
      state.deleteState = "loading"
    })

    builder.addCase(deleteDevice.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.deleteState = "success"
      } else {
        state.deleteState = "failed"
      }
    })
    builder.addCase(deleteDevice.rejected, (state, action) => {
      state.deleteState = "failed"
    })
    builder.addCase(updateDevice.pending, (state, action) => {
      state.updateState = "loading"
    })

    builder.addCase(updateDevice.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.updateState = "success"
      } else {
        state.updateState = "failed"
      }
    })
    builder.addCase(updateDevice.rejected, (state, action) => {
      state.updateState = "failed"
    })

    builder.addCase(addDeviceOtp.pending, (state, action) => {
      state.addState = "loading"
    })

    builder.addCase(addDeviceOtp.fulfilled, (state, action) => {
      if (action.payload.success) {
        state.addState = "success"
      } else {
        state.addState = "failed"
      }
    })
    builder.addCase(addDeviceOtp.rejected, (state, action) => {
      state.addState = "failed"
    })

  }
})

export {fetchDevices, updateDevice, deleteDevice, addDeviceOtp}
export const {clearState, clearDeleteState,clearUpdateState, clearAddState} = DevicesSlice.actions;
export default DevicesSlice.reducer