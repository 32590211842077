import {createSlice} from "@reduxjs/toolkit";

const defaultConfig = {
    resolution: {
        height: 1080,
        width: 1920
    },
    codec_string:"avc1.64003E",
    bitrate: 10 * 1024 * 1024,
    framerate: 60,
    codec: 'h264',
    color_format: 'rgb',
    adapter_idx: 0,
    output_idx : 0,
    change_resolution: false,
    hardware_acceleration: 'prefer-hardware' ,
    extra_opt: {
        source: "",
        filter: "",
        processor: ""
    }
}

const LOCALSTORE_KEY = "stream_settings"
let streamSettings;

let storedSettings = window.localStorage.getItem(LOCALSTORE_KEY);
if (storedSettings) {
    streamSettings = Object.assign({}, defaultConfig, JSON.parse(storedSettings));
} else {
    streamSettings = defaultConfig;
}

const SettingsSlice = createSlice({
    name: "settings",
    initialState: streamSettings,
    reducers: {
        setStreamConfig: (state, action) => {
            state = action.payload
            window.localStorage.setItem(LOCALSTORE_KEY, JSON.stringify(state))
            console.log("saving to persistent store")
            return state
        }
    },
})


export const {setStreamConfig} = SettingsSlice.actions;
export default SettingsSlice.reducer