import React, {useEffect} from "react";
import VirtGamepad from "./gamepad";

let gamepads = {}
const GamepadInput = ({cli}) => {
  const onGamepadConnected = (ev) => {
    if (ev.gamepad.buttons.length >= 17) {
      let gp = new VirtGamepad(cli, ev.gamepad);
      gamepads[ev.gamepad.index] = gp;
    }
  }
  const onGamepadDisconnected = (ev) => {
    let gp = gamepads[ev.gamepad.index];
    gp.disconnect();
    delete gamepads[ev.gamepad.index]
  }
  const gameloop = () => {
    let current_gamepads = navigator.getGamepads();
    for (const [idx, gp] of Object.entries(gamepads)) {
      gp.sendInput(current_gamepads[idx])
    }
    requestAnimationFrame(gameloop)
  }
  useEffect(() => {
    if (cli) {
      window.addEventListener("gamepadconnected", onGamepadConnected)
      window.addEventListener("gamepaddisconnected", onGamepadDisconnected)
      let start = requestAnimationFrame(gameloop);
      return () => {
        for (const [_, gp] of Object.entries(gamepads)) {
          gp.disconnect()
        }
        gamepads = {};
        window.removeEventListener("gamepadconnected", onGamepadConnected)
        window.removeEventListener("gamepaddisconnected", onGamepadDisconnected)
        cancelAnimationFrame(start);
      }
    }

  }, [cli]);


  return <></>
}
export default GamepadInput;