import {createSlice} from "@reduxjs/toolkit";


const AudioSlice = createSlice({
  name: "audio",
  initialState: {
    volume: 100
  },
  reducers: {
    setVolume: (state, action) => {
      state.volume = action.payload
    }
  },
})


export const {setVolume} = AudioSlice.actions;
export default AudioSlice.reducer