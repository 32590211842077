import {createSlice} from "@reduxjs/toolkit";

const defaultConfig = {
  cursor_capture: true,
  movement_multiplier: 1,
  scroll_multiplier: 1
}

const LOCALSTORE_KEY = "client_settings"
let clientSettings;

let storedSettings = window.localStorage.getItem(LOCALSTORE_KEY);
if (storedSettings) {
  clientSettings = Object.assign({}, defaultConfig, JSON.parse(storedSettings));
} else {
  clientSettings = defaultConfig;
}

const ClientSettingsSlice = createSlice({
  name: "client_settings",
  initialState: clientSettings,
  reducers: {
    setClientConfig: (state, action) => {
      state = action.payload
      window.localStorage.setItem(LOCALSTORE_KEY, JSON.stringify(state))
      console.log("saving to persistent store")
      return state
    }
  },
})


export const {setClientConfig} = ClientSettingsSlice.actions;
export default ClientSettingsSlice.reducer