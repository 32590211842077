import apiLinks from "./apiLinks";
import {generatePath} from "react-router-dom";

export const getDevices = async () => {
  let resp = await fetch(apiLinks.device.base + apiLinks.device.list, {
    method: 'GET', credentials: 'include', mode: 'cors',
  })

  if (resp.status === 200) {
    return resp.json()
  } else {
    throw resp.status
  }
}
export const deleteDeviceApi = async (deviceId) => {
  let path = generatePath(apiLinks.device.delete, {device_id: deviceId})
  let resp = await fetch(apiLinks.device.base + path, {
    method: 'DELETE', credentials: 'include', mode: 'cors',
  })

  if (resp.status === 200) {
    return resp.json()
  } else {
    throw resp.status
  }
}
export const updateDeviceApi = async (deviceData) => {
  let path = generatePath(apiLinks.device.update, {device_id: deviceData.id})
  let resp = await fetch(apiLinks.device.base + path, {
    method: 'PUT', credentials: 'include', mode: 'cors', body: JSON.stringify(deviceData)
  })

  if (resp.status === 200) {
    return await resp.json()
  } else {
    throw resp.status
  }
}

export const addDeviceOtpApi = async (otp) => {
  let path = apiLinks.add_device
  let resp = await fetch(path, {
    method: 'POST', credentials: 'include', mode: 'cors', body: JSON.stringify({otp: otp})
  })
  if (resp.status === 200){
    return await resp.json()
  } else {
    throw resp.status
  }
}