import apiLinks from "./apiLinks";

const signup = async (payload) => {
  const resp = await fetch(apiLinks.signup, {
    method: "POST",
    credentials: 'include',
    mode: 'cors',
    body: JSON.stringify(payload),
  })
  if (200 <= resp.status && resp.status < 300) {
    return {success: true}
  } else {
    const data = await resp.json();
    return {success: false, data}
  }
}
export default signup;