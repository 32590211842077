import apiLinks from "./apiLinks";

const logout = async () => {
  const resp = await fetch(apiLinks.logout, {
    method: "GET",
    credentials: 'include',
    mode: 'cors',
  })
  if (resp.status === 200) {
    return {
      success: true
    }
  } else {
    let data = await resp.json()
    return {
      success: false,
      data
    }

  }

}
export default logout